<template>
  <div class="model-creation">
    <Card class="main mb-1">
      <template v-slot:header>
        <div class="grid formgrid p-3 mr-1 p-fluid">
          <div class="col-12 md:col-4 pl-4 mt-2">
            <ProjectDetails />
          </div>
          <div class="col-4 md:col-2 col-offset-0 md:col-offset-2 mt-2">
            <Button :label="t('app.form.label.cancel')" class="p-button-outlined" @click="$emit('cancel')" :disabled="cancel" />
          </div>
          <div class="col-4 md:col-2 mt-2">
            <Button :label="t('app.form.label.back')" icon="pi pi-angle-left" @click="$emit('back')" :disabled="back" />
          </div>
          <div class="col-4 md:col-2 mt-2">
            <Button :label="forwardLabel || t('app.form.label.forward')" icon="pi pi-angle-right" iconPos="right"
                    @click="$emit('forward')" :disabled="forward" :loading="forwardLoading" />
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div v-if="layout.mainContentVisible" class="pl-3 pr-3">
          <slot name="mainContent"></slot>
        </div>
      </template>
    </Card>
    <transition name="fade" mode="out-in">
      <Card v-if="layout.detailsTitleVisible || layout.detailsContentVisible" class="details">
        <template v-slot:title>
          <transition name="fade" mode="out-in">
            <div v-if="layout.detailsTitleVisible" class="details-title pl-3 pt-3 pr-3">
              <slot name="detailsTitle"></slot>
            </div>
          </transition>
        </template>
        <template v-slot:content>
          <transition name="fade" mode="out-in">
            <div v-if="layout.detailsContentVisible" class="p-3">
              <slot name="detailsContent"></slot>
            </div>
          </transition>
        </template>
      </Card>
    </transition>
  </div>
</template>

<script>
import ProjectDetails from './ProjectDetails'

import { useI18n } from 'vue-i18n'
import { onMounted, onUpdated, reactive } from 'vue'

export default {
  name: 'ProjectLayout',
  props: {
    cancel: { type: String },
    back: { type: String },
    forward: { type: String },
    forwardLabel: { type: String },
    forwardLoading: { type: Boolean }
  },
  emits: ['cancel', 'back', 'forward'],
  components: { ProjectDetails },
  setup (props, { slots }) {
    const { t } = useI18n()

    const layout = reactive({
      mainContentVisible: false,
      detailsTitleVisible: false,
      detailsContentVisible: false
    })

    const displaySlots = () => {
      layout.mainContentVisible = !!slots.mainContent
      layout.detailsTitleVisible = !!slots.detailsTitle
      layout.detailsContentVisible = !!slots.detailsContent
    }

    onMounted(() => displaySlots())
    onUpdated(() => displaySlots())

    return { t, layout }
  }
}
</script>

<style lang="scss">
.details.p-card .p-card-title > .details-title {
  font-size: 1.2rem;
}
</style>
