import { reactive } from 'vue'

const useDispatcher = function (inputKeys) {

  const dispatches = reactive(new Map())

  if (inputKeys) {
    inputKeys.forEach(inputKey => dispatches.set(inputKey, { result: null, success: false, error: false, pending: 0 }))
  }

  const initInput = (inputKey) => {
    if (!dispatches.has(inputKey)) {
      dispatches.set(inputKey, { result: null, success: false, error: false, pending: 0 })
    }
  }

  const handleUpdate = (inputKey, value) => {
    initInput(inputKey)

    const state = dispatches.get(inputKey)
    if (state.pending === 0 && value) {
      state.success = true
      state.error = false
      state.result = value
      dispatches.set(inputKey, state)
    }
  }

  const handleDispatch = (inputKey, inputSubmit) => {
    initInput(inputKey)

    if (inputSubmit && inputSubmit.then && typeof inputSubmit.then === 'function') {
      const state = dispatches.get(inputKey)
      state.pending++
      state.success = false
      state.error = false
      dispatches.set(inputKey, state)

      inputSubmit
        .then(result => {
          const state = dispatches.get(inputKey)
          if (state.pending - 1 === 0) {
            state.success = true
            state.error = false
            state.result = result
            dispatches.set(inputKey, state)
          }
          return result
        })
        .catch(() => {
          const state = dispatches.get(inputKey)
          if (state.pending - 1 === 0) {
            state.success = false
            state.error = true
            dispatches.set(inputKey, state)
          }
        })
        .finally(() => {
          const state = dispatches.get(inputKey)
          state.pending--
          dispatches.set(inputKey, state)
        })
    }

    return inputSubmit
  }

  return { dispatches, handleUpdate, handleDispatch }
}

export { useDispatcher }
